<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <CImg src="sa-logo.png" class="center"/>
                  <h4 class="title text-center"> </h4>
                  <p class="text-muted text-center">Login using Gmail or Google account</p>
                  
                  <CRow>
                    <CCol class="text-center"> 
                      <g-signin-button :params="googleSignInParams"
                        @success="onSignInSuccess"
                        @error="onSignInError"
                        style="width: 50%; cursor: pointer">
                        <CImg src="icon-google.svg"
                          style="width: 24px; height: 24px ;margin-right: 8px; margin-left: 0px"/>
                          Google
                      </g-signin-button>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol class="text-center"> 
                    <hr>
                    <span class="text-center small">
                        version {{ version }}
                    </span>
                    </CCol>             
                  </CRow>
                  <CRow>
                    <CCol class="text-center" style="margin-top:20px">
                      <CLink
                        @click="onClickInquiry">
                        Mendaftar
                      </CLink>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper>
              <CCardBody>
                <h2>Mendaftar</h2>
                <p>Pelayanan Sion melakukan pemuridan untuk mahasiswa dan alumni kampus-kampus di Indonesia, sebagai pelaksanaan dari Amanat Agung dari Tuhan Yesus Kristus (Mat 28:19-20). Silakan mendaftar bila Anda adalah pembimbing atau sudah di-instruksikan oleh pembimbing yang telah ditentukan.</p>
                <CButton
                  @click="onClickInquiry"
                  color="light"
                  variant="outline"
                  size="lg">
                  Lanjut
                </CButton>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import api from "../../api/api"
export default {
  data() {
    return {
      version : process.env.VUE_APP_VERSION = require('../../../package.json').version,
      googleSignInParams: {
          client_id: '234775930215-l1biiqktbutfod1o31cempcvnipe47kv.apps.googleusercontent.com' // Localhost
      }
    };
  },
  async created() {
    localStorage.setItem('idToken', undefined)
  },

  methods: {
    onClickInquiry(){
      this.$router.push('/signup', () => {})
    },

    onSignInSuccess(googleUser) {
      var userProfile = googleUser.getBasicProfile()
      var idToken = process.env.NODE_ENV + '|' + googleUser.getAuthResponse().id_token

      console.log("googleUser.getAuthResponse(): " + JSON.stringify(googleUser.getAuthResponse()))
      console.log("googleUser.getBasicProfile(): " + JSON.stringify(userProfile))

      this.getUser(idToken)
    },

    onSignInError(error) {
      this.$alert("Login error, you can try again.")
    },

    async getUser(idToken) {
      let apiRes = await api.getUser(idToken)
      if (apiRes.result === 'OK') {
        let user = apiRes.user
        if(user.isValidated==="N")
          this.$alert("Silakan cek email Anda dari Sion Academy untuk mengaktifkan akun.")
        else{
          localStorage.setItem('idToken', idToken)
          localStorage.setItem('user', JSON.stringify(user))

          this.$router.replace('/discipleship/bahan-pa', () => {})
        }
      }
      else {
        console.log("apiRes: " + JSON.stringify(apiRes))

        if(apiRes.err === 'ERR01')
          this.$alert("Anda belum terdaftar. Silakan mendaftar bila ingin berpartisipasi di dalam pemuridan.")
      }
    }
  }
}
</script>

<style>
.g-signin-button {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
}
.center {
  display: block;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>
